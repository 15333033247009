import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/contact"
import SEO from "../components/seo"
import Heading from "../components/modules/heading"
import Outline from "../components/modules/outline"
import tw, { css } from "twin.macro"

const Title = tw.div`relative flex pt-32 z-20 pb-24 flex-col items-center justify-center w-full`
const Subtitle = tw.p`font-mont font-bold text-xl -mt-2`

const BgTextStyles = [
  tw`absolute z-10 max-w-full overflow-hidden whitespace-nowrap`,
  css`
    left: -1.5rem;
    top: 17rem;
  `,
]

export default ({ data }) => {
  //highlight-line
  const page = data.wpPage
  const settings = data.wp.contactPageSettings
  const robots = {
    name: `robots`,
    content: `${page.seo.metaRobotsNoindex}, ${page.seo.metaRobotsNofollow}`,
  }
  return (
    <Layout>
      <SEO
        title={page.seo.title}
        description={page.seo.metaDesc}
        meta={[robots]}
        schema={page.seo.schema?.raw}
      />
      <Outline css={BgTextStyles} size="15rem" color="#f7f3e6">
        MARRONE
      </Outline>
      <Title>
        <Heading centered>{settings.heading.contactTitle}</Heading>
        <Subtitle>{settings.heading.contactSubtitle}</Subtitle>
      </Title>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    wp {
      contactPageSettings {
        heading {
          contactSubtitle
          contactTitle
        }
      }
    }
    wpPage(id: { eq: $id }) {
      seo {
        ...SEO
      }
    }
  }
`
