import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import GlobalStyles from "../modules/global-styles"
import Footer from "../partials/footer"
import Header from "../partials/header"
import "../../styles/global.css"
import "../../styles/layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
      </Helmet>
      <GlobalStyles />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
